
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Creative from '@/models/Creative'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { VastMediaFile } from 'vast-client'
import DataTable from '@/components/DataTable/index.vue'
// @ts-ignore
import * as FileSaver from 'file-saver'
import axios from 'axios'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Fields from './creative-table-fields'
import AssociatedItemsTable from './components/AssociatedItemsTable.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    AssociatedItemsTable,
  },
})
export default class CreativeHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly player!: HTMLFormElement

  @Ref() readonly canvas!: HTMLFormElement

  public creative: Creative = new Creative()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public show_player_controls: boolean = false

  // dataTable field filters
  public fieldFilters: any = {
    type: '',
    adserver: '',
  }

  public modal: any = {
    preview: false,
    tag: false,
  }

  public media_file: VastMediaFile | null = null

  public query: string[] = ['adserver:cim']

  public loading_creative: boolean = false

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public fields: any = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public show_filter_helper: boolean = false

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Include only the specified advertiser',
      },
    ],
    order_fields: [
      {
        name: 'order',
        key: 'order_id',
        description: 'Include only creatives associated with the specified order',
      },
    ],
    line_item_fields: [
      {
        name: 'line_item',
        key: 'line_item_id',
        description: 'Include only creatives associated with the specified line item',
      },
    ],
    custom_fields: [
      {
        name: 'has:associated_orders',
        value: 'has:associated_orders',
        description: 'Include only creatives that are associated with an Order',
      },
      {
        name: 'has:associated_line_items',
        value: 'has:associated_line_items',
        description: 'Include only creatives that are associated with a Line Item',
      },
      {
        name: 'type:vast',
        value: 'type:vast',
        description: 'Include only VAST creatives',
      },
      {
        name: 'type:video',
        value: 'type:video',
        description: 'Include only video creatives',
      },
      {
        name: 'adserver:cim',
        value: 'adserver:cim',
        description: 'Include only orders that are using CIM adserver',
      },
      {
        name: 'adserver:gam',
        value: 'adserver:gam',
        description: 'Include only orders that are using GAM adserver',
      },
    ],
  }

  public creatives(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return Creative.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public createCreative() {
    this.$router.push('/app/creative')
  }

  public confirmTag(creative: Creative) {
    this.creative = creative
    this.modal.tag = true
  }

  public generateTAG() {
    this.creative.updateTag()
  }

  public previewCreative(creative: Creative) {
    this.creative = creative
    this.media_file = null
    this.loading_creative = true
    this.creative.preview().then(async (r: VastMediaFile) => {
      if (r) {
        this.media_file = r
        axios.get(r.fileURL, { responseType: 'blob' }).then(response => {
          this.media_file.media = new File([response.data], 'x.mp4', response.type)
          this.media_file.localUrl = window.URL.createObjectURL(this.media_file.media)
          this.loading_creative = false
        })
        this.modal.preview = true
      }
    })
  }

  public editCreative(item: Creative) {
    this.$router.push(`/app/creative/${item.id}`)
  }

  public created() {
    this.fields = [...Fields]
  }

  public mounted() {
    this.loadFilters()
  }

  public resetFilters() {
    Vue.set(this, 'fieldFilters', {
      type: '',
      adserver: '',
    })
    this.clearFilters()

    this.query = ['adserver:cim']
    this.dataTable.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'creatives',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('creatives').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    this.filter_mode = 'exclusive'
    system.updateState({
      name: 'filters',
      type: 'creatives',
      data: null,
    })
  }

  public captureVideoFrame(e: any) {
    e.preventDefault()
    this.player.pause()
    this.canvas.width = this.player.videoWidth
    this.canvas.height = this.player.videoHeight
    this.canvas
      .getContext('2d')
      .drawImage(this.player, 0, 0, this.player.videoWidth, this.player.videoHeight)

    this.canvas.toBlob(blob => {
      FileSaver.saveAs(blob, `${this.creative.name}.preview.png`)
    })
  }
}
